@import "./variables"
@import "./breakpoints"
@import "./themes"
@import "~normalize.css"

@font-face
  font-family: 'Gilroy'
  font-display: swap
  src: local('Gilroy'), url('/fonts/Gilroy/Gilroy-Regular.woff2')
  font-weight: normal
  font-style: normal
@font-face
  font-family: 'Gilroy'
  font-display: swap
  src: local('Gilroy'), url('/fonts/Gilroy/Gilroy-Medium.woff2')
  font-weight: 500
  font-style: normal
@font-face
  font-family: 'Gilroy'
  font-display: swap
  src: local('Gilroy'), url('/fonts/Gilroy/Gilroy-SemiBold.woff2')
  font-weight: 600
  font-style: normal
@font-face
  font-family: 'Gilroy'
  font-display: swap
  src: local('Gilroy'), url('/fonts/Gilroy/Gilroy-Bold.woff2')
  font-weight: 700
  font-style: normal

body
  overscroll-behavior-y: none
  min-width: 320px

body, html
  background-color: var(--wt-white2-dt-black2)
  font-family: 'Gilroy', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif
  font-size: 14px
  color: var(--wt-blue3-dt-white2)

h1
  margin: 0

.ReactModal__Body--open
  overflow: hidden
  position: fixed
  width: 100%
  height: 100%

.Modal__Overlay
  z-index: 100
  position: fixed
  top: 0
  left: 0
  bottom: 0
  right: 0
  background: linear-gradient(0deg, rgba(234, 241, 249, 0.1), rgba(234, 241, 249, 0.1))
  backdrop-filter: blur(10px)
  display: flex
  align-items: flex-start
  justify-content: center
  cursor: pointer
  //padding: 20px 0
  overflow-y: auto
  max-height: 100vh

.Modal__Content
  outline: none
  cursor: default
  //width: 100%
  min-height: 100%
  box-sizing: border-box
  display: flex
  align-items: center
  justify-content: center
  position: relative
  z-index: 10
  //padding: 20px 0
  //padding: 20px 115px



.react-sel
  z-index: 5
  width: 60px
  position: relative
  &::before
    content: ''
    position: absolute
    border-radius: 10px
    top: -1px
    bottom: -1px
    left: -1px
    right: -1px
    background: var(--border-12)
    z-index: 1

  input
    outline: none !important

  .react-select__control
    border: none
    position: relative
    z-index: 5
    background: var(--wt-white2-dt-black2)
    box-shadow: var(--box-shadow-5)
    border-radius: 10px
    max-width: 60px
    min-height: 30px
    max-height: 30px
    cursor: pointer

  .react-select__control--menu-is-open
    .react-select__indicator
      svg
        transform: rotate(-180deg)


  .react-select__value-container
    padding: 0 0 0 10px
    font-size: 1rem
    font-weight: 600
    color: var(--wt-blue3-dt-white2)
    opacity: 1
    line-height: 1
    max-height: 30px
    box-sizing: border-box
    span
      text-shadow: var(--text-shadow-2)
      margin-top: 2px
      color: var(--wt-blue3-dt-white2)

  .react-select__option
    padding: 7px 20px
    line-height: 1.43
    color: var(--wt-blue3-dt-white2)
    &--is-focused, &--is-selected
      background: #E3EDF7
    span
      color: var(--wt-blue3-dt-white2)

  .react-select__indicator-separator
    display: none

  .react-select__indicator
    z-index: 10
    max-height: 30px
    padding: 0
    padding-right: 6px
    svg
      transition: transform $animationTime
      path
        fill: var(--wt-blue3-dt-blue1)

  .react-select__menu
    background: var(--wt-white2-dt-black2)
    box-shadow: var(--box-shadow-5)
    border-radius: 10px
    overflow: hidden
    z-index: 15
    max-width: 60px
    cursor: pointer
    &-notice--no-options
      color: var(--wt-blue3-dt-white2)
      opacity: .6


//.notification
//  padding: 0 40px 40px
//  min-height: 100vh
//  box-sizing: border-box
//  padding-left: 300px
//  @media screen and ( min-width: 1560px )
//    min-width: 1560px
//    width: 1560px
//    margin: 0 auto
//    height: 100%
//    position: relative
//    padding-left: 310px

.notification
  position: fixed
  width: calc(100% - 340px)
  z-index: 25
  top: 30px
  right: auto
  left: 300px
  padding: 0
  @media screen and ( min-width: 1560px )
    min-width: 1560px
    width: 1560px
    box-sizing: border-box
    margin: 0 auto
    padding-left: 310px
    left: 50%
    transform: translateX(-50%)
    padding-right: 40px
  @include media('<=Ltablet')
    width: calc(100% - 64px)
    left: 32px
  @include media('<=Lphone')
    width: calc(100% - 32px)
    left: 16px
  .Toastify__close-button
    display: none
  .Toastify__toast-body
    width: 100%
  .Toastify__toast
    font-family: Gilroy
    background-color: var(--wt-white3-dt-black2)
    padding: 20px 35px
    border-radius: 20px
    box-shadow: var(--box-shadow-28)
    margin-bottom: 20px
    display: flex
    align-items: center
    @include media('<=Ltablet')
      padding: 20px
  .Toastify__progress-bar
    animation: Toastify__trackProgress linear 1

@keyframes Toastify__trackProgress
  0%
    transform: scaleX(1)
  100%
    transform: scaleX(0)


/*** iPhone and iOS Form Input Zoom Fixes ***/
@media screen and (device-aspect-ratio: 2/3)
  select, textarea, input[type="text"], input[type="username"], input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"]
    font-size: 16px !important
@media screen and (device-aspect-ratio: 40/71)
  select, textarea, input[type="text"], input[type="username"], input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"]
    font-size: 16px !important
@media screen and (device-aspect-ratio: 375/667)
  select, textarea, input[type="text"], input[type="username"], input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="tel"], input[type="url"]
    font-size: 16px !important
@media screen and (device-aspect-ratio: 9/16)
  select, textarea, input[type="text"], input[type="username"], input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="tel"], input[type="url"]
    font-size: 16px !important
html
    //colors
    --wt-white1-dt-black7: #2A3039
    --wt-black-dt-white2: #F1F3F6
    --wt-blue2-dt-blue8: #BDD4FF
    --wt-blue3-dt-blue1: #387EFF
    --wt-green1-dt-blue1: #387EFF
    --wt-blue3-dt-blue5: #88A6D2
    --wt-blue3-dt-blue6: #75A6FF
    --wt-blue3-dt-blue12: #1E5BCB
    --wt-white2-dt-blue12: #1E5BCB
    --wt-blue3-dt-gray5: #3C4656
    --wt-blue3-dt-white1: #FFFFFF
    --wt-blue3-dt-white2: #F1F3F6
    --wt-blue3-dt-white3: #F7F8F9
    --wt-blue4-dt-blue5: #465C7D
    --wt-blue13-dt-blue5: #88A6D2
    --wt-blue14-dt-gray2: #404957
    --wt-blue15-dt-transparent: transparent
    --wt-blue16-dt-blue1: #387EFF
    --wt-blue16-dt-white2: #F1F3F6
    --wt-blue17-dt-blue18: #3775E6
    --wt-gray1-dt-blue1: #387EFF
    --wt-gray1-dt-blue5: #88A6D2
    --wt-gray1-dt-white2: #F1F3F6
    --wt-gray1-dt-gray10: #505C6F
    --wt-gray3-dt-blue5: #88A6D2
    --wt-gray4-dt-blue5: #88A6D2
    --wt-gray3-dt-white2: #F1F3F6
    --wt-gray4-dt-white2: #F1F3F6
    --wt-gray6-dt-gray5: #3C4656
    --wt-gray6-dt-white6: #D8DFF3
    --wt-gray7-dt-black3: #242B35
    --wt-gray8-dt-gray9: #7C8596
    --wt-gray8-dt-white2: #F1F3F6
    --wt-gray9-dt-gray1: #8F9BB2
    --wt-gray10-dt-gray11: rgba(60, 70, 86, 0.6)
    --wt-red1-dt-red2: #EA4444
    --wt-red1-dt-red3: #F45151
    --wt-transparent-dt-blue1: #387EFF
    --wt-transparent-dt-white4: #EBEFFA
    --wt-white1-dt-blue5: #88A6D2
    --wt-white2-dt-black2: #1D232C
    --wt-white2-dt-blue1: #387EFF
    --wt-white2-dt-transparent: transparent
    --wt-white2-dt-white2: #F1F3F6
    --wt-white3-dt-black1: #11171F
    --wt-white3-dt-black2: #1D232C
    --wt-white4-dt-black2: #1D232C
    --wt-white4-dt-transparent: transparent
    --wt-white5-dt-black1: #11171F
    --wt-white5-dt-blue1: #387EFF
    --wt-white6-dt-gray2: #404957
    --wt-gray4-dt-gray2: #404957
    --wt-white6-dt-gray5: #3C4656
    --wt-white7-dt-black5: #313C4B
    --wt-white8-dt-black6: #181E27
    --wt-white9-dt-black1: #11171F
    --wt-white9-dt-transparent: transparent
    --wt-white10-dt-transparent: transparent
    --wt-white11-dt-black1: #11171F
    --wt-white12-dt-black1: #11171F
    //shadows
    --box-shadow-1: 5px 5px 20px #161B23
    --box-shadow-2-outside: -5px -5px 10px #242B35, 5px 5px 15px #10151D
    --box-shadow-2-inside: inset -5px -5px 10px #2C69D9, inset 5px 5px 15px #0E48B5
    --box-shadow-3: linear-gradient(124.76deg, #092964 19.6%, #1556CE 59.46%)
    --box-shadow-4-outside: -4px -4px 10px #242B35, 5px 5px 15px #10151D
    --box-shadow-4-inside: -5px -5px 15px #242B35, inset -5px -5px 7px #2A3039, inset 5px 5px 7px #181E27
    --box-shadow-5: -4px -4px 10px #242B35, 5px 5px 15px #10151D
    --box-shadow-6: -5px -5px 15px #242B35, 5px 5px 15px #10151D, inset -5px -5px 7px #2A3039, inset 5px 5px 7px #181E27
    --box-shadow-7: linear-gradient(289.4deg, #0D1218 -2.9%, #343C48 100.51%)
    --box-shadow-8-outside: 5px 5px 15px 0px #10151D, -4px -4px 10px 0px #242B35
    --box-shadow-8-inside: inset -5px -5px 10px #272E38, inset 5px 5px 15px #161C23
    --box-shadow-9: -4px -4px 5px #242B35, 1px 2px 8px #10151D, inset 3px 3px 7px #0E367E, inset -4px -4px 7px #2357B9
    --box-shadow-10: -4px -4px 5px #242B35, 1px 2px 8px #10151D, inset -2px -2px 7px #A7C0ED, inset 2px 2px 10px #3E6EC8
    --box-shadow-11: -10px -10px 7px #242B35, 7px 7px 15px #10151D
    --box-shadow-12: inset 5px 5px 15px #181E27, inset -5px -5px 15px #2A3039
    --box-shadow-13: -4px -4px 5px #242B35, 1px 2px 8px #10151D
    --box-shadow-14: inset 4px 4px 7px #0A3991, inset -4px -4px 7px #2357B9
    --box-shadow-15: inset 4px 4px 7px #0A3991, inset -4px -4px 7px #2357B9
    --box-shadow-16: -4px -4px 5px #242B35, 3px 3px 10px #10151D
    --box-shadow-17: -4px -4px 5px #242B35, 3px 3px 10px #10151D
    --box-shadow-18: -4px -4px 10px #242B35, 5px 5px 15px #10151D
    --box-shadow-19: -5px -5px 15px #242B35, 5px 5px 15px #10151D, inset -5px -5px 7px #2A3039, inset 5px 5px 7px #181E27
    --box-shadow-20: -4px -4px 5px #242B35, 1px 2px 8px #10151D, inset 3px 3px 7px #2267E7, inset -4px -4px 7px #4A8AFF
    --box-shadow-21: linear-gradient(124.76deg, #092964 19.6%, #1556CE 59.46%)
    --box-shadow-22-outside: -5px -5px 10px #242B35, 5px 5px 15px #10151D
    --box-shadow-22-inside: inset -5px -5px 10px #2C69D9, inset 5px 5px 15px #0E48B5
    --box-shadow-23: inset 3px 3px 5px #242B35, inset -4px -4px 5px #10151D
    --box-shadow-24: -4px -4px 5px #242B35, 1px 2px 8px #10151D
    --box-shadow-25: inset 4px 4px 5px #242B35, inset -3px -3px 5px #10151D
    --box-shadow-26: -4px -4px 10px #242B35, 5px 5px 15px #10151D
    --box-shadow-27: -4px -4px 10px #242B35, 5px 5px 15px #10151D
    --box-shadow-28: -4px -4px 10px #242B35, 5px 5px 15px #10151D
    --box-shadow-29: -4px -4px 10px #242B35, 5px 5px 15px #10151D
    --box-shadow-30-outside: -4px -4px 10px #242B35, 2px 2px 7px #10151D
    --box-shadow-30-inside: inset 5px 5px 15px 0px #10151D
    --box-shadow-31: 5px 5px 20px #161B23
    --box-shadow-32: 2px -2px 3px #4A8AFF, 1px 1px 5px #2267E7, inset 3px 3px 7px #5EAF74, inset -4px -4px 7px #9FEAB4
    --box-shadow-33: -2px -2px 3px #4A8AFF, 1px 1px 5px #2267E7, inset -3px -3px 7px #F43838, inset 4px 4px 7px #E92323
    --box-shadow-34: none
    --box-shadow-35: -4px -4px 10px #242B35, 5px 5px 15px #10151D
    --box-shadow-36: -5px -5px 10px #2969DF, 5px 5px 15px #124DB9

    //borders
    --border-1: linear-gradient(289.4deg, #0D1218 -2.9%, #343C48 100.51%)
    --border-2: none
    --border-3: #1D232C
    --border-4: linear-gradient(124.76deg, #092964 19.6%, #1556CE 59.46%)
    --border-5: linear-gradient(167.17deg, #6F9BEC -4.06%, #FFFFFF 95.36%)
    --border-6: linear-gradient(60.11deg, #457FE8 -40.87%, rgba(29, 90, 203, 0.99) 37.35%)
    --border-7: linear-gradient(289.4deg, #0D1218 -2.9%, #343C48 100.51%)
    --border-8: 3px solid #1D232C
    --border-9: #1E5BCB
    --border-10: linear-gradient(124.76deg, #092964 19.6%, #1556CE 59.46%)
    --border-11: 0.2px solid linear-gradient(289.4deg, #0D1218 -2.9%, #343C48 100.51%)
    --border-12: linear-gradient(289.4deg, #0D1218 -2.9%, #343C48 100.51%)
    --border-13: linear-gradient(289.4deg, #0D1218 -2.9%, #343C48 100.51%)
    --border-14: linear-gradient(289.4deg, #0D1218 -2.9%, #343C48 100.51%)
    --border-15: linear-gradient(124.76deg, #092964 19.6%, #1556CE 59.46%)
    --border-16: linear-gradient(289.4deg, #0D1218 -2.9%, #343C48 100.51%), linear-gradient(0deg, #EBEFFA, #EBEFFA)
    --border-17: #387EFF
    --border-18: linear-gradient(124.76deg, #092964 19.6%, #1556CE 59.46%)
    --border-19: linear-gradient(289.4deg, #0D1218 -2.9%, #343C48 100.51%)

    //text-shadows
    --text-shadow-1: -1px -1px 2px #242B35, 1px 1px 2px #10151D
    --text-shadow-2: -1px -1px 2px #242B35, 1px 1px 2px #10151D
    --text-shadow-3: -1px -1px 2px #242B35, 1px 1px 2px #10151D
    --text-shadow-4: -2px -2px 3px #242B35, 1px 1px 2px #10151D
    --text-shadow-5: none

    --color-scheme: dark
    
    --darkDisplayBlock: block
    --lightDisplayBlock: none
    --darkDisplayOpacity: 1

    --wt-15px-dt-8px: 8px

html[data-theme="light"]
    //default light theme
    --wt-white1-dt-black7: #FFFFFF
    --wt-black-dt-white2: #000
    --wt-blue2-dt-blue8: #6F9BEC
    --wt-blue3-dt-blue1: #163878
        
    --wt-blue3-dt-blue5: #163878
    --wt-blue3-dt-blue6: #163878
    --wt-blue3-dt-blue12: #163878
    --wt-blue3-dt-gray5: #163878
    --wt-blue3-dt-white1: #163878
    --wt-blue3-dt-white2: #163878
    --wt-blue3-dt-white2: #163878
    --wt-blue3-dt-white3: #163878
    --wt-blue4-dt-blue5: #B7C3D9
    --wt-blue13-dt-blue5: #99add2
    --wt-blue14-dt-gray2: #d8dff6
    --wt-blue15-dt-transparent: #BED5FF
    --wt-blue16-dt-blue1: #0B7BE1
    --wt-blue16-dt-white2: #0B7BE1
    --wt-blue17-dt-blue18: #3F6EC6
    --wt-gray1-dt-blue1: #8F9BB2
    --wt-gray1-dt-blue5: #8F9BB2
    --wt-gray1-dt-white2: #8F9BB2
    --wt-gray1-dt-gray10: #8F9BB2
    --wt-gray3-dt-blue5: #31456a
    --wt-gray3-dt-white2: #31456a
    --wt-gray3-dt-white2: #31456a
    --wt-gray4-dt-gray2: #8E9BB6
    --wt-gray4-dt-blue5: #8E9BB6
    --wt-gray6-dt-gray5: rgba(216, 223, 243, 0.46)
    --wt-gray6-dt-white6: rgba(216, 223, 243, 0.46)
    --wt-gray7-dt-black3: rgba(255, 255, 255, 0.62)
    --wt-gray8-dt-gray9: #C2C6D3
    --wt-gray8-dt-white2: #C2C6D3
    --wt-gray9-dt-gray1: #707070
    --wt-gray10-dt-gray11: rgba(216, 223, 243, 0.6)
    --wt-red1-dt-red2: #EB7A7A
    --wt-red1-dt-red3: #EB7A7A
    --wt-transparent-dt-blue1: transparent
    --wt-transparent-dt-white4: transparent
    --wt-white1-dt-blue5: #FFFFFF
    --wt-white2-dt-black2: #F1F3F6
    --wt-white2-dt-blue1: #F1F3F6
    --wt-white2-dt-transparent: #F1F3F6
    --wt-white2-dt-white2: #F1F3F6
    --wt-white2-dt-blue12: #F1F3F6
    --wt-white3-dt-black1: #F7F8F9
    --wt-white3-dt-black2: #F7F8F9
    --wt-white4-dt-black2: #EBEFFA
    --wt-white4-dt-transparent: #EBEFFA
    --wt-white5-dt-black1: #e2e7ee
    --wt-white5-dt-blue1: #e2e7ee
    --wt-white6-dt-gray2: #D8DFF3
    --wt-white6-dt-gray5: #D8DFF3
    --wt-white7-dt-black5: #D2DCEB
    --wt-white8-dt-black6: #D1DAE8
    --wt-white9-dt-black1: #E8ECF1
    --wt-white9-dt-transparent: #E8ECF1
    --wt-white10-dt-transparent: #F5F5F5
    --wt-white11-dt-black1: #e1e6ed
    --wt-white12-dt-black1: #e6eaef
    //shadows
    --box-shadow-1: 10px 10px 20px #dce2e9
    --box-shadow-2-outside: -5px -5px 10px #ffffff, 5px 5px 15px rgb(154 168 189 / 57%)
    --box-shadow-2-inside: inset -5px -5px 15px #2e4e8b, inset 5px 5px 15px #082866
    --box-shadow-3: linear-gradient(87.1deg, #FFFFFF -3.02%, #3865B9 96.54%)
    --box-shadow-4-outside: -4px -4px 10px #FFFFFF, 5px 5px 15px #DAE0E9
    --box-shadow-4-inside:5px -5px 15px rgba(255, 255, 255, 0.88), inset -5px -5px 7px #FFFFFF, 5px 5px 15px rgba(218, 224, 233, 0.79), inset 5px 5px 7px #D1DAE8
    --box-shadow-5: -4px -4px 10px #FFFFFF, 5px 5px 15px #DAE0E9
    --box-shadow-6: 5px 5px 15px rgba(218, 224, 233, 0.79), -5px -5px 15px rgba(255, 255, 255, 0.88), inset 5px 5px 7px #D1DAE8, inset -5px -5px 7px #FFFFFF
    --box-shadow-7: linear-gradient(132.16deg, #FEFEFF 26.7%, #C6CEDA 96.52%)
    --box-shadow-8-outside: -10px -10px 20px rgba(255, 255, 255, 0.85), 10px 10px 20px #C5CEDB
    --box-shadow-8-inside: inset 5px 5px 10px #D1DAE8, inset -5px -5px 10px rgba(255, 255, 255, 0.85)
    --box-shadow-9: -4px -4px 5px rgba(255, 255, 255, 0.62), 1px 2px 8px rgba(56, 91, 138, 0.25), inset 3px 3px 7px #011E52, inset -4px -4px 7px #5B80C3
    --box-shadow-10:-4px -4px 5px rgba(255, 255, 255, 0.6), 1px 2px 8px rgba(149, 189, 244, 0.4), inset -1px -1px 10px #C9DCFF, inset 1px 1px 5px #3E6EC8
    --box-shadow-11: -10px -10px 7px rgba(255, 255, 255, .76), 7px 7px 15px #DCE1E9
    --box-shadow-12: inset 5px 5px 15px rgba(212, 222, 237, .85), inset -5px -5px 15px #ffffff
    --box-shadow-13: -4px -4px 5px rgba(255, 255, 255, 0.6), 1px 2px 8px rgba(61, 94, 139, 0.32)
    --box-shadow-14: inset 4px 4px 7px #011E52, inset -4px -4px 7px #5B80C4
    --box-shadow-15: inset -2px -2px 7px #C9DCFF, inset 2px 2px 10px #3E6EC8
    --box-shadow-16: 5px 5px 15px #DAE0E9, -4px -4px 10px #FFFFFF
    --box-shadow-17: -4px -4px 5px #FFFFFF, 3px 3px 10px #D9E0F3
    --box-shadow-18: -4px -4px 7px #FFFFFF, 5px 5px 7px #DAE0E9
    --box-shadow-19: none
    --box-shadow-20: -4px -4px 5px #FFFFFF, 3px 3px 10px #D9E0F3
    --box-shadow-21: linear-gradient(132.16deg, #FEFEFF 26.7%, #C6CEDA 96.52%)
    --box-shadow-22-outside: -10px -10px 20px rgba(255, 255, 255, 0.85), 10px 10px 20px #C5CEDB
    --box-shadow-22-inside: inset 5px 5px 10px #D1DAE8, inset -5px -5px 10px rgba(255, 255, 255, 0.85)
    --box-shadow-23: inset -4px -4px 5px #DAE0E9, inset 3px 3px 5px #FFFFFF
    --box-shadow-24: 1px 2px 8px #DAE0E9, -4px -4px 5px #FFFFFF, inset 4px 4px 7px #DAE0E9, inset -3px -3px 7px #FFFFFF
    --box-shadow-25: inset 4px 4px 5px #DAE0E9, inset -3px -3px 5px #FFFFFF
    --box-shadow-26: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d
    --box-shadow-27: -3px -3px 7px #FFFFFF, 3px 3px 7px #DAE0E9
    --box-shadow-28: 10px 10px 20px #dce2e9
    --box-shadow-29: -5px -5px 10px rgba(255, 255, 255, 0.85), 2px 2px 7px #C5CEDB
    --box-shadow-30-outside: -5px -5px 10px rgba(255, 255, 255, 0.85), 2px 2px 7px #C5CEDB
    --box-shadow-30-inside: inset 3px 3px 5px #C5CEDB, inset -3px -3px 5px rgba(255, 255, 255, 0.85)
    --box-shadow-31: 10px 10px 20px #DCE2E9
    --box-shadow-32: -4px -4px 5px rgba(255, 255, 255, 0.62), 1px 2px 8px rgba(49, 117, 68, 0.25), inset 3px 3px 7px #5EAF74, inset -4px -4px 7px #9FEAB4
    --box-shadow-33: -4px -4px 5px rgba(255, 255, 255, 0.62), 1px 2px 8px rgba(233, 35, 35, 0.25), inset -3px -3px 7px #F43838, inset 4px 4px 7px #E92323
    --box-shadow-34: linear-gradient(275.59deg, #D2DCEB -2.9%, #FFFFFF 100.51%)
    --box-shadow-35: 5px 5px 15px #DAE0E9, -4px -4px 10px #FFFFFF
    --box-shadow-36: -2px -2px 5px #436DBA, 2px 2px 5px #06245E

    //borders
    --border-1: linear-gradient(273.48deg, #D2DCEB -1.31%, #FFFFFF 98.94%)
    --border-2: linear-gradient(283.75deg, #D2DCEB -2.9%, #FFFFFF 100.51%)
    --border-3: linear-gradient(134.17deg, #EEF0F5 4.98%, #E6E9EF 94.88%)
    --border-4: linear-gradient(60.11deg, #092964 -40.87%, rgba(22, 56, 120, 0.99) 37.35%)
    --border-5: #6F9BEC
    --border-6: linear-gradient(60.11deg, #092964 -40.87%, rgba(22, 56, 120, 0.99) 37.35%)
    --border-7: linear-gradient(272.04deg, #D2DCEB -1.31%, #FFFFFF 98.94%)
    --border-8: none
    --border-9: linear-gradient(134.17deg,#eef0f5 4.98%,#e6e9ef 94.88%)
    --border-10: 1px solid #618AC0
    --border-11: 0.2px solid #010101
    --border-12: none
    --border-13: linear-gradient(124.28deg, #FFFFFF 35.92%, #A0B1CC 190.57%)
    --border-14: linear-gradient(272.04deg, #D2DCEB -1.31%, #FFFFFF 98.94%)
    --border-15: linear-gradient(272.36deg, #D2DCEB -1.31%, #FFFFFF 98.94%)
    --border-16: #EBEFFA
    --border-17: linear-gradient(143.22deg, #418F57 64.53%, rgba(87, 164, 109, 0.99) 95.78%)
    --border-18: none
    --border-19: linear-gradient(283.75deg, #D2DCEB -2.9%, #FFFFFF 100.51%)

    //text shadows
    --text-shadow-1: 1px 1px 2px rgba(173, 183, 198, 0.77), -1px -1px 2px #FFFFFF
    --text-shadow-2: -1px -1px 2px #FFFFFF, 1px 1px 2px rgba(173, 183, 198, 0.77) 
    --text-shadow-3: -1px -1px 2px #FFFFFF, 1px 1px 2px #B4BECC
    --text-shadow-4: -2px -2px 3px #FFFFFF, 1px 1px 2px rgba(173, 183, 198, 0.79)
    --text-shadow-5: 3px 3px 7px #cbd4e1, -2px -2px 5px #ffffff

    --color-scheme: light

    --darkDisplayBlock: none
    --lightDisplayBlock: block
    --darkDisplayOpacity: 0

    --wt-15px-dt-8px: 15px